import React from 'react'
import { Box, EmptyState } from 'components'
import { useCurrentVendorLocation, useFilteredVendorProducts } from '@kitchen/hooks'
import { Products as VendorProducts, ProductsFilters  } from '@kitchen/compositions/Products'
import Skeleton from '@mui/material/Skeleton'
import ReceiptIcon from '@mui/icons-material/Receipt'

export const Products: React.FC = () => {
  const { vendorLocation } = useCurrentVendorLocation()

  const {
    loading,
    loadedAt,
    error,
    filters: {
      pagination,
      applyPagination,
      sorting,
      applySorting,
      ...filters
    },
    data: { filteredProducts }
  } = useFilteredVendorProducts(vendorLocation)

  return <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
    <ProductsFilters
      loading={loading}
      loadedAt={loadedAt}
      {...filters}
    />

    { ((!filteredProducts || filteredProducts.length === 0) && (loading)) && <Skeleton variant="rounded" animation="pulse" height="100%" /> }
    { (filteredProducts && filteredProducts.length > 0) && <VendorProducts products={filteredProducts} pagination={pagination} applyPagination={applyPagination} sorting={sorting} applySorting={applySorting} display={filters.filters.display} /> }
    { ((filteredProducts && filteredProducts.length === 0) && (!loading && !error)) && <EmptyState icon={ReceiptIcon} message="No data to display." /> }
  </Box>
}

export default Products
