import React from 'react'
import { Box, EmptyState } from 'components'
import { useCurrentVendorLocation, useFilteredVendorFulfillmentReports } from '@kitchen/hooks'
import { FulfillmentReport, ReportFilters} from '@kitchen/compositions/FulfillmentReport'
import Skeleton from '@mui/material/Skeleton'
import { useQueryParams } from 'hooks/useQueryParams'
import ReceiptIcon from '@mui/icons-material/Receipt'

export const Fulfillment: React.FC = () => {
  const params = useQueryParams<{
    startDate: string,
    endDate: string
  }>({
    startDate: undefined,
    endDate: undefined
  })

  const { vendorLocation } = useCurrentVendorLocation()

  const {
    loading,
    loadedAt,
    error,
    filters,
    data: { filteredVendorFulfillmentReports }
  } = useFilteredVendorFulfillmentReports(
    vendorLocation,
    {
      startDate: params.startDate,
      endDate: params.endDate,
    }
  )

  return <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
    <ReportFilters
      loading={loading}
      loadedAt={loadedAt}
      {...filters}
    />

    { ((!filteredVendorFulfillmentReports || filteredVendorFulfillmentReports.length === 0) && (loading)) && <Skeleton variant="rounded" animation="pulse" height="100%" /> }
    { ((filteredVendorFulfillmentReports && filteredVendorFulfillmentReports.length > 0) || (!loading && !error)) && <FulfillmentReport reports={filteredVendorFulfillmentReports} display={filters.filters.display} /> }
    { ((filteredVendorFulfillmentReports && filteredVendorFulfillmentReports.length === 0) && (!loading && !error)) && <EmptyState icon={ReceiptIcon} message="No data to display." /> }
  </Box>
}

export default Fulfillment
