import { useCallback, useEffect, useMemo } from 'react'
import { setTag } from '@sentry/react'
import { enqueueSnackbar } from 'notistack'
import { useMe } from 'hooks'
import { useKitchenContext } from './useKitchenContext'
import { VendorLocation } from '@kitchen/graphql/schema/graphql'

export type UseCurrentVendorLocationPayload = {
  vendorLocation?: VendorLocation
  setCurrentVendorLocation: (location: VendorLocation) => void
}

export const useCurrentVendorLocation = (): UseCurrentVendorLocationPayload => {
  const { currentVendorLocation, setCurrentVendorLocation: setCurrentVendorLocationContext } = useKitchenContext()
  const { data: { me }, hasLocationRole } = useMe()
  const vendorLocations = useMemo(() => me?.vendorLocations?.filter((loc) => hasLocationRole('STAFF', loc)) || [], [ me, hasLocationRole ])
  const vendorLocation = useMemo(() => vendorLocations?.find((loc) => loc.id === currentVendorLocation?.id) || vendorLocations?.[0], [ currentVendorLocation, vendorLocations ])

  const setCurrentVendorLocation = useCallback((location: VendorLocation) => {
    setCurrentVendorLocationContext(location)

    if (currentVendorLocation?.id !== location?.id) {
      enqueueSnackbar(`Switched to ${location?.name || "New Campus"}`, { autoHideDuration: 2000, preventDuplicate: true })
    }
  }, [ currentVendorLocation, setCurrentVendorLocationContext ])

  useEffect(() => {
    if (vendorLocation && vendorLocations.length > 0 && !vendorLocations.map(loc => loc.id).includes(vendorLocation.id))
      setCurrentVendorLocationContext(null)

    if (vendorLocation) {
      setTag("vendorLocation", `${vendorLocation.id} (${vendorLocation.name})`)
    } else {
      setTag("vendorLocation", null)
    }
  }, [ vendorLocation, vendorLocations, setCurrentVendorLocationContext ])

  return {
    vendorLocation,
    setCurrentVendorLocation,
  }
}

export default useCurrentVendorLocation
