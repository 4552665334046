import React from 'react'
import { Box } from 'components'
import { useCurrentVendorLocation, useFilteredVendorMenus } from '@kitchen/hooks'
import { useQueryParams } from 'hooks/useQueryParams'
import { Menus as VendorMenus, MenusFilters  } from '@kitchen/compositions/Menus'
import Skeleton from '@mui/material/Skeleton'

export const Menus: React.FC = () => {
  const params = useQueryParams<{
    startDate: string,
    endDate: string
  }>({
    startDate: undefined,
    endDate: undefined
  })

  const { vendorLocation } = useCurrentVendorLocation()

  const {
    loading,
    loadedAt,
    filters,
    data: { filteredMenus }
  } = useFilteredVendorMenus(
    vendorLocation,
    {
      startDate: params.startDate,
      endDate: params.endDate
    }
  )

  return <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
    <MenusFilters
      loading={loading}
      loadedAt={loadedAt}
      {...filters}
    />

    { ((!filteredMenus || filteredMenus.length === 0) && (loading)) && <Skeleton variant="rounded" animation="pulse" height="100%" /> }
    { (filteredMenus && (filteredMenus.length > 0 || !loading)) && <VendorMenus menus={filteredMenus} appliedFilters={filters.appliedFilters} /> }
  </Box>
}

export default Menus
